<template>
  <div>
    <v-row>
      <v-col lg="6">
        <zone :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100"> >
        </zone>        
      </v-col>
      <v-col lg="6">
         <sub-zone :isAdmin="true"  :ZonID="ZonID" :height="200"> >
        </sub-zone> 
             
      </v-col>
  
      
     
    </v-row>
  </div>
</template>

<script>
    
import SubZone from './SubZone.vue';
import Zone from './Zone.vue';
export default {
  components: {
       
    Zone,
    SubZone
      
  },

   data() {
    return {
      parentID: 0,
      ZonID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.ZonID=rows[0].ZonID;
  },
   
  },
};
</script>
