<template>  
    <s-crud 
    title="Sub-Zonas"
    :config="configSubzonas"
    @save="save($event)"
    edit
    add
    remove   
    :filter="filter"
    search-input  
    ref="crud"

    >  
    <template scope="props">
        <!--  <v-container class="pb-0"> -->
          <v-row justify="center">
             <!-- <v-col lg="4" class="s-col-form">
                <s-select-definition v-model="props.item.ZonID" label="Zona" add :def="1072" ></s-select-definition>
              </v-col>-->
             <v-col cols="10" class="s-col-form">
                <s-text v-model="props.item.SzoDescription" label="Descripción" > </s-text>
                <s-select-ubigee v-model="props.item.UbgID" label="Ubigeo (DPTO-PROV-DIST)" clearable autocomplete>  </s-select-ubigee>
              </v-col>              
              <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
              <s-select-definition v-model="props.item.SzoStatus" :def="1218" label="Estado"></s-select-definition>
            </v-col> 
          </v-row>    
          </template>
     <template v-slot:DedDescription="{ row }">
        <v-chip style="margin:0px" x-small :color="row.DedDescription == 'Inactivo' ? 'error' : 'success'">
            {{ row.DedDescription }}
        </v-chip>
      </template> 
     </s-crud>

</template>


<script>
import _sVarietyService from "@/services/Technicalassistance/SubZonaService";
import SSelectUbigee from '../../../components/TasSubzone/sSelectUbigee.vue';


export default {
  name: "RequirementServiceSubzona",
  components: {SSelectUbigee  },
  props: {
    ZonID:{ type: Number, default: 0 },
    
  },

  data() {
    return {
      dialogEdit: false,   
     };     
  },

  computed: {
   
    configSubzonas() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          SzoID: "ID",
          SzoDescription: "string",         
          DedDescription: "string", 
              SzoStatus: "status",
             DedDescription:"String",   
          //
          }, 
        service: _sVarietyService,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "SzoID", sortable: false },
              { text: "Descripción", value: "SzoDescription", sortable: false },
              { text: "Zona", value: "ZonDescription", sortable: false },
              { text: "Ubigeo (DPTO-PROV-DIST)", value: "UbgDescription", sortable: false }, 
              { text: "Estado", value: "DedDescription", sortable: false }, 
                        
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
            { text: "ID", value: "SzoID", sortable: false },
            { text: "Descripción", value: "SzoDescription", sortable: false },
            { text: "Zona", value: "ZonDescription", sortable: false }, 
             { text: "Ubigeo (DPTO-PROV-DIST)", value: "UbgDescription", sortable: false },     
            { text: "Estado", value: "DedDescription", sortable: false },            
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

 Initialize() {
      this.filter = {ZonID: this.ZonID};
     
    },
    edit(item) {
      this.dialogEdit = true;    
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit("rowSelected", rows);       
      }
    },

    save(item) {
     // console.log(item);
     item.ZonID=this.ZonID
      
      if (this.$fun.getSecurity().IsLevelAdmin) {   
       //VALIDACIONES
      if (item.SzoDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la SubZona", "warning");
          return;
        }
        if (item.ZonID == 0) {
        this.$fun.alert("Seleccione la zona", "warning");
        return; 
        }
       
        item.UsrCreateID=0;
      item.save();
      }
       else {
        
 if (item.SzoDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la subzona", "warning");
          return;
        }
        if (item.ZonID == 0) {
        this.$fun.alert("Seleccione el zona", "warning");
        return; 
        }
        item.UsrCreateID=0;
      item.save();

      } 
      
      
    },    
  },
  watch: {
    
    ZonID(){   
        
      this.Initialize();    
      },
  },
   created() {
    this.filter = { ZonID: this.ZonID };
  },
};
</script>
