<template>
  <s-crud @returnObject='refresh($event)'
    no-border
    title="Zonas"
    :config="this.config"
    @save="saveDed($event)"
    add
    @clearForm="clearForm()"
    edit
    restore
    @rowSelected="rowSelected($event)" 
    remove
    :filter="filter"
   
  >
    <template scope="props">
      <v-container style="" v-if="props.item != null" class="pb-0">
        <v-row>
          <v-col cols="6" class="s-col-form">
            <s-text autofocus ref="ZonDescription" v-model="props.item.ZonDescription" label="Descripción " />
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-select-definition v-model="props.item.ZonStatus" :def="1217" label="Estado"></s-select-definition>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:DedDescription="{ row }">
        <v-chip style="margin:0px" x-small :color="row.DedDescription == 'Inactivo' ? 'error' : 'success'">
            {{ row.DedDescription }}
        </v-chip>
      </template> 
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/Technicalassistance/ZoneService.js";
export default {
  props: {
    //zonID: 0,
   
    //defDescription: "",
    ZonDescription:"",
  },
  data: () => ({
    dedValue:0,
      config: {
      title: "Definiciones Detalles",
      service: _sDefinitionDetail,
      model: {
        
        ZonID: "ID",
        ZonDescription: "string",
        SecStatus: "status",
        ZonStatus: "status",
        DedDescription:"String",
      },
      headers: [
          {
            //
          text: "ID",
          value: "ZonID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Definicion",
          value: "ZonDescription",
          width: "55%",
          sorteable: true,
        },
       
        {
          text: "Estado",
          value: "DedDescription",
          width: "20%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),

  computed: {
   
    filter() {
      return {  };
    },
  },
  methods: {
 rowSelected(items) {
      if (items.length > 0) this.ZonaID = items[0].ZonID;
      console.log(items);
      this.$emit("rowSelected", items);
  },

    refresh(item){
      this.$emit('refresh',item);
    },
    clearForm() {
      this.$refs.ZonDescription.focus();
    },
    saveDed(item) {
      item.SecStatus=1;
       if (item.ZonDescription.length == 0) {
        this.$fun.alert("Registre descripción de valor", "warning");
        return;
      }
      item.save();       
    },
  },
};
</script>
